import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';

import { Button } from '../../components/button.component';
import { MenuLayout } from '../../components/menu-layout.component';
import { useUser } from '../../hooks/use-user.hook';
import LineLogo from '../../assets/line-logo.svg';


export default function Home() {
  const { t, navigate } = useI18next();

  const { loggedIn } = useUser();

  useEffect(() => {
    if(loggedIn) {
      navigate('/register');
    }
  }, [ loggedIn, navigate ]);

  const handleBackButtonTrigger = () => {
    const historyPage = window.sessionStorage.getItem('current_page');
    if (historyPage) {
      navigate(historyPage);
    } else {
      navigate('/');
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', handleBackButtonTrigger);
    return () => window.removeEventListener('popstate', handleBackButtonTrigger);
  }, []);

  return <MenuLayout disableSecureLayout={true} className="transparent">

    <div className="register">
      <div className="header">
        <h1 className="t-heading1">{ t('Test kit registration') }</h1>
        <p className="t-sans header__subheading t-p1 t-grey9">{t('Thank you for applying for the inspection. First of all, please register the test kit online')}</p>
      </div>
      <div className="section">
        <div className="button-accounts">
          <Button to="/account/create">{ t('Create account') }</Button>
          <Button buttonType="line" href="/api/users/line-auth"><LineLogo className="line-logo" />{t('Sign up with LINE')}</Button>
          <Button buttonType="default" onClick={() => navigate('/account/login')}>{ t('Login') }</Button>
          <Button className="underline resetbtn" buttonType="text" size="small" onClick={() => navigate('/account/reset-password')}>{t('Reset password')}</Button>
        </div>
      </div>
    </div>
  </MenuLayout>;
}
